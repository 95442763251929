<template>
  <div class="opacity-50">
    <svg class="absolute right-full hidden translate-x-1/2 translate-y-[900px] transform lg:block" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-blue-base" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="404" height="1300" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"></rect>
    </svg>
  </div>
  <div class="md:mb-20 mb-10 md:mt-44 mt-32">
    <h1 class="text-center text-white font-extrabold text-5xl  ">Des services informatiques sur mesure</h1>
    <hr class="w-48 h-1 mx-auto mt-4 bg-blue-base border-0 rounded md:mt-10">
  </div>

  <div>
    <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
      <div class="relative overflow-hidden rounded-xl bg-blue-base py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
        <div class="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
          <img src="@/assets/telecom_nancy_tns.jpg" alt="" class="h-full w-full object-cover" />
        </div>
        <div class="relative lg:col-span-1">
          <img class="h-20 w-auto" src="@/assets/tns_logo_complet_blanc_fond_transparent_300ppp.png" alt="" />
          <blockquote class="mt-6 text-white">
            <p class="text-xl font-medium sm:text-2xl">TNS vous propose ses services de développement pour réponde à vos besoins, et ce dans de nombreux domaines de
              l'informatique.<br><br>Qu'il s'agisse de développement web, d'applications mobiles, de traitement ou d'analyse de données, nous sauront
              répondre à vos attentes en réalisant vos projets sur mesure.</p>
          </blockquote>
        </div>
      </div>
    </div>
  </div>

  <div class=" md:mt-72 mt-32">
    <h1 class="text-center text-white font-extrabold text-5xl  ">Nos prestations</h1>
    <hr class="w-48 h-1 mx-auto mt-4 bg-blue-base border-0 rounded md:mt-10">
  </div>

  <div class="relative px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
    <div class="absolute inset-0">
      <div class="h-1/3 sm:h-2/3" />
    </div>
    <div class="relative mx-auto max-w-7xl">
      <div class="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
        <div v-for="post in posts" :key="post.title" class="flex flex-col overflow-hidden rounded-lg shadow-lg">
          <div class="flex-shrink-0">
            <img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
          </div>
          <div class="flex flex-1 flex-col justify-between bg-white p-6">
            <div class="flex-1">
                <p class="text-xl font-semibold text-gray-900">{{ post.title }}</p>
                <p class="mt-3 text-base text-gray-500">{{ post.description }}</p>
            </div>
            <div v-if="post.images" class="pt-5">
              <div class="space-x-1 grid grid-cols-5 items-center">
                <div v-for="image in post.images" :key="image" class="justify-self-center flex-shrink-0 inline-block w-10">
                  <img class="filter grayscale pb-3" :src="image" :alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const posts = [
  {
    title: 'Développement web',
    href: '#',
    description:
        'De l’artisan aux grandes entreprises, un site web est désormais la vitrine la plus vue de votre organisation. TNS met à votre portée les compétences indispensables à la création ou l’optimisation de votre site. Faites le pas et modernisez les interactions avec votre clientèle !',
    imageUrl:
        '/assets/images/illustrations/web.jpeg',
    images: [
        '/assets/images/icons/drupal.png',
        '/assets/images/icons/jdee.png',
        '/assets/images/icons/jquery.png',
        '/assets/images/icons/jsp.png',
        '/assets/images/icons/mssql.png',
        '/assets/images/icons/mysql.webp',
        '/assets/images/icons/oracle.png',
        '/assets/images/icons/php.png',
        '/assets/images/icons/symfony.png',
        '/assets/images/icons/wordpress.png',
    ]
  },
  {
    title: 'Génie logiciel',
    description:
        'Les programmes grand public ne correspondent pas à votre activité ? Confiez-nous vos idées et vous aurez un programme qui répondra parfaitement à vos besoins. TNS saura porter un regard créatif et innovateur pour la réalisation, l’amélioration ou remplacement de logiciels existants.',
    imageUrl:
        '/assets/images/illustrations/logiciel.jpeg',
    images: [

      '/assets/images/icons/c++.png',
      '/assets/images/icons/c.png',
      '/assets/images/icons/csharp.png',
      '/assets/images/icons/gtk.png',
      '/assets/images/icons/java.png',
      '/assets/images/icons/opengl.png',
      '/assets/images/icons/python.png',
      '/assets/images/icons/qt.png',
      '/assets/images/icons/WPF.png',
      '/assets/images/icons/wcf.png',
    ]
  },
  {
    title: 'Applications mobiles',
    description:
        'Android, iOS, Windows Phone, Responsive design… Vous souhaitez élargir votre clientèle, ou juste lui faciliter l’accès à vos services ? Vous envisagez de créer un évènement promotionnel ? Vous pensez renouveler votre fonctionnement interne ? Nous pouvons développer des applications sur toutes les plateformes mobiles existantes, afin de vous permettre de moderniser et dynamiser votre activité !',
    imageUrl:
        '/assets/images/illustrations/mobile.jpeg',
  },
  {
    title: 'Architecture réseaux et systèmes',
    description:
        'Audit, installation, mise à niveau... Si vous envisagez de créer ou modifier votre réseau ou système informatique mais ne savez pas quel chemin suivre, demandez-nous conseil ! Un dimensionnement et des mises à niveau régulières de ces outils vous permettront d’optimiser au mieux votre productivité.',
    imageUrl:
        '/assets/images/illustrations/reseau.webp',
  },
  {
    title: 'Big data et intelligence artificielle',
    description:
        'Les données révolutionnent l\'ensemble des secteurs grâce à l\'intelligence artificielle. Vous souhaitez tirer partie des données en votre possession ? N\'hésitez pas à faire appel à TNS !',
    imageUrl:
        '/assets/images/illustrations/ia.jpeg',
  },
  {
    title: 'Cybersécurité',
    description:
        'RGPD, Malware, Virus.. Vous connaissez ces mots mais pas les notions derrières ? Vous souhaitez être sensibilisé aux nouvelles réglementations ? TNS saura répondre à vos demandes et interrogations !',
    imageUrl:
        '/assets/images/illustrations/cyber.jpeg',
  },
]
</script>

<script>
import CarouselServices from '@/components/Accueil/CarouselServices.vue';
import Expertises from '@/../data/expertises.json'

export default {
  components: {CarouselServices},
  data() {
    return {
      expertises: Expertises
    }
  },
  mounted() {
    // scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },
  methods: {
    getPicture(image) {
      try {
        return require("@/assets/illustrations/" + image)
      } catch (e) {
        return require("@/assets/defaultMemberIcon.png")
      }
    },
  }
}
</script>
