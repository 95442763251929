import { createRouter, createWebHistory } from 'vue-router'

import Accueil from '../views/Accueil.vue'
import About from '../views/About.vue'
import Prestations from '../views/Prestations.vue'
import Contact from '../views/Contact.vue'
import Recrutement from '../views/Recrutement.vue'
import Merci from '../views/Merci.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    alias: '/accueil',
    name: 'home',
    component: Accueil,
    meta: {
      title: 'Accueil | Telecom Nancy Services',
        metaTags: [
            {
                name: 'description',
                content: 'Bienvenue sur le site web de Telecom Nancy Services, l\'entreprise dirigée par des étudiants spécialisée dans l\'informatique, les réseaux et la création de sites web. Nous offrons des solutions innovantes pour répondre à tous vos besoins en matière de technologie.'
            },
            {
              name: 'title',
              content: 'Accueil | Telecom Nancy Services'
            },
            {
                property: 'og:description',
                content: 'Bienvenue sur le site web de Telecom Nancy Services, l\'entreprise dirigée par des étudiants spécialisée dans l\'informatique, les réseaux et la création de sites web. Nous offrons des solutions innovantes pour répondre à tous vos besoins en matière de technologie.'
            },
            {
              property: 'og:title',
              content: 'Accueil | Telecom Nancy Services'
            },
            {
                property: 'og:url',
                content: 'https://tnservices.fr/'
            },
            {
                property: 'og:image',
                content: 'https://tnservices.fr/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'twitter:card',
                content: 'summary_large_image'
            },
            {
                property: 'twitter:url',
                content: 'https://tnservices.fr/'
            },
            {
                property: 'twitter:title',
                content: 'Accueil | Telecom Nancy Services'
            },
            {
                property: 'twitter:description',
                content: 'Bienvenue sur le site web de Telecom Nancy Services, l\'entreprise dirigée par des étudiants spécialisée dans l\'informatique, les réseaux et la création de sites web. Nous offrons des solutions innovantes pour répondre à tous vos besoins en matière de technologie.'
            },
            {
                property: 'twitter:image',
                content: 'https://tnservices.fr/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            }
        ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'A propos | Telecom Nancy Services',
        metaTags: [
            {
                name: 'description',
                content: 'Découvrez qui nous sommes et ce que nous faisons chez Telecom Nancy Services. Nous sommes une entreprise dirigée par des étudiants qui se consacre à l\'informatique, aux réseaux et à la création de sites web. Nous avons une passion pour la technologie et nous aimons aider les gens à résoudre leurs problèmes informatiques.'
            },
            {
                name: 'title',
                content: 'A propos | Telecom Nancy Services'
            },
            {
                property: 'og:description',
                content: 'Découvrez qui nous sommes et ce que nous faisons chez Telecom Nancy Services. Nous sommes une entreprise dirigée par des étudiants qui se consacre à l\'informatique, aux réseaux et à la création de sites web. Nous avons une passion pour la technologie et nous aimons aider les gens à résoudre leurs problèmes informatiques.'
            },
            {
                property: 'og:title',
                content: 'A propos | Telecom Nancy Services'
            },
            {
                property: 'og:url',
                content: 'https://tnservices.fr/about'
            },
            {
                property: 'og:image',
                content: 'https://tnservices.fr/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image'
            },
            {
                property: 'twitter:url',
                content: 'https://tnservices.fr/about'
            },
            {
                property: 'twitter:title',
                content: 'A propos | Telecom Nancy Services'
            },
            {
                property: 'twitter:description',
                content: 'Découvrez qui nous sommes et ce que nous faisons chez Telecom Nancy Services. Nous sommes une entreprise dirigée par des étudiants qui se consacre à l\'informatique, aux réseaux et à la création de sites web. Nous avons une passion pour la technologie et nous aimons aider les gens à résoudre leurs problèmes informatiques.'
            },
            {
                property: 'twitter:image',
                content: 'https://tnservices.fr/img/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            }
        ]
    }
  },
  {
    path: '/prestations',
    name: 'prestations',
    component: Prestations,
    meta: {
      title: 'Prestations | Telecom Nancy Services',
        metaTags: [
            {
                name: 'description',
                content: 'Chez Telecom Nancy Services, nous offrons une variété de services pour répondre à tous vos besoins en matière de technologie. Que vous ayez besoin d\'aide pour créer un site web, de conseils en matière de réseaux ou de support informatique, nous sommes là pour vous. Consultez notre liste de prestations pour en savoir plus sur ce que nous pouvons faire pour vous.'
            },
            {
                name: 'title',
                content: 'Prestations | Telecom Nancy Services'
            },
            {
                property: 'og:description',
                content: 'Chez Telecom Nancy Services, nous offrons une variété de services pour répondre à tous vos besoins en matière de technologie. Que vous ayez besoin d\'aide pour créer un site web, de conseils en matière de réseaux ou de support informatique, nous sommes là pour vous. Consultez notre liste de prestations pour en savoir plus sur ce que nous pouvons faire pour vous.'
            },
            {
                property: 'og:title',
                content: 'Prestations | Telecom Nancy Services'
            },
            {
                property: 'og:url',
                content: 'https://tnservices.fr/prestations'
            },
            {
                property: 'og:image',
                content: 'https://tnservices.fr/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image'
            },
            {
                property: 'twitter:url',
                content: 'https://tnservices.fr/prestations'
            },
            {
                property: 'twitter:title',
                content: 'Prestations | Telecom Nancy Services'
            },
            {
                property: 'twitter:description',
                content: 'Chez Telecom Nancy Services, nous offrons une variété de services pour répondre à tous vos besoins en matière de technologie. Que vous ayez besoin d\'aide pour créer un site web, de conseils en matière de réseaux ou de support informatique, nous sommes là pour vous. Consultez notre liste de prestations pour en savoir plus sur ce que nous pouvons faire pour vous.'
            },
            {
                property: 'twitter:image',
                content: 'https://tnservices.fr/img/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            }
        ]
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Contact | Telecom Nancy Services',
        metaTags: [
            {
                name: 'description',
                content: 'Nous aimerions entendre de vous ! Si vous avez des questions ou des commentaires, n\'hésitez pas à nous contacter. Vous pouvez nous appeler, nous envoyer un e-mail ou remplir notre formulaire de contact sur cette page. Nous nous ferons un plaisir de répondre à toutes vos demandes dans les plus brefs délais.'
            },
            {
                name: 'title',
                content: 'Contact | Telecom Nancy Services'
            },
            {
                property: 'og:description',
                content: 'Nous aimerions entendre de vous ! Si vous avez des questions ou des commentaires, n\'hésitez pas à nous contacter. Vous pouvez nous appeler, nous envoyer un e-mail ou remplir notre formulaire de contact sur cette page. Nous nous ferons un plaisir de répondre à toutes vos demandes dans les plus brefs délais.'
            },
            {
                property: 'og:title',
                content: 'Contact | Telecom Nancy Services'
            },
            {
                property: 'og:url',
                content: 'https://tnservices.fr/contact'
            },
            {
                property: 'og:image',
                content: 'https://tnservices.fr/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image'
            },
            {
                property: 'twitter:url',
                content: 'https://tnservices.fr/contact'
            },
            {
                property: 'twitter:title',
                content: 'Contact | Telecom Nancy Services'
            },
            {
                property: 'twitter:description',
                content: 'Nous aimerions entendre de vous ! Si vous avez des questions ou des commentaires, n\'hésitez pas à nous contacter. Vous pouvez nous appeler, nous envoyer un e-mail ou remplir notre formulaire de contact sur cette page. Nous nous ferons un plaisir de répondre à toutes vos demandes dans les plus brefs délais.'
            },
            {
                property: 'twitter:image',
                content: 'https://tnservices.fr/img/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            }
        ]
    }
  },
  {
    path: '/recrutement',
    name: 'recrutement',
    component: Recrutement,
    meta: {
      title: 'Recrutement | Telecom Nancy Services',
        metaTags: [
            {
                name: 'description',
                content: 'Telecom Nancy Services est constamment à la recherche de talents en informatique, réseaux et création de sites web pour rejoindre notre équipe. Si vous êtes passionné par la technologie et que vous avez le désir de travailler avec une entreprise dirigée par des étudiants, consultez notre page de recrutement pour voir les opportunités actuelles.'
            },
            {
                name: 'title',
                content: 'Recrutement | Telecom Nancy Services'
            },
            {
                property: 'og:description',
                content: 'Telecom Nancy Services est constamment à la recherche de talents en informatique, réseaux et création de sites web pour rejoindre notre équipe. Si vous êtes passionné par la technologie et que vous avez le désir de travailler avec une entreprise dirigée par des étudiants, consultez notre page de recrutement pour voir les opportunités actuelles.'
            },
            {
                property: 'og:title',
                content: 'Recrutement | Telecom Nancy Services'
            },
            {
                property: 'og:url',
                content: 'https://tnservices.fr/recrutement'
            },
            {
                property: 'og:image',
                content: 'https://tnservices.fr/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image'
            },
            {
                property: 'twitter:url',
                content: 'https://tnservices.fr/recrutement'
            },
            {
                property: 'twitter:title',
                content: 'Recrutement | Telecom Nancy Services'
            },
            {
                property: 'twitter:description',
                content: 'Telecom Nancy Services est constamment à la recherche de talents en informatique, réseaux et création de sites web pour rejoindre notre équipe. Si vous êtes passionné par la technologie et que vous avez le désir de travailler avec une entreprise dirigée par des étudiants, consultez notre page de recrutement pour voir les opportunités actuelles.'
            },
            {
                property: 'twitter:image',
                content: 'https://tnservices.fr/img/tns_logo_icon_couleur_fond_transparent_72dpi.png'
            }
        ]
    }
  },
  {
    path: '/merci',
    name: 'merci',
    component: Merci,
    meta: {
      title: 'Merci pour votre message ! - Telecom Nancy Services'
    }
  },
  {
    // 404
    path: '/not-found',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 - Telecom Nancy Services'
    }
  },
  {
    // 404
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 - Telecom Nancy Services'
    }
  }
]

const router = createRouter({
  mode: history,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});
